import { api } from "./api";
const apiUrl = "https://admin.icba.uk/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getExchangeRates: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/exchange-rate/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getLanguages: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/language/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    addLanguage: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/settings/language/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getTerms: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/term/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    addTerm: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/settings/term/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getEducationLevels: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/education-level/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    addEducationLevel: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/settings/education-level/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    sendSms: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/sms/send`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    searchCertificate: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/searchCertificate`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/auth/change-password`,
        credentials: "include",
        body: { data },
        method: "POST",
      }),
    }),
    addDiscount: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/settings/discount/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getDiscountList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/settings/discount/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetExchangeRatesQuery,
  useGetLanguagesQuery,
  useAddLanguageMutation,
  useGetTermsQuery,
  useAddTermMutation,
  useGetEducationLevelsQuery,
  useAddEducationLevelMutation,
  useSendSmsMutation,
  useSearchCertificateQuery,
  useChangePasswordMutation,
  useAddDiscountMutation,
  useGetDiscountListQuery,
} = extendedApi;
