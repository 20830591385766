import { api } from "./api";
const apiUrl = "https://admin.icba.uk/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addStudent: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getStudents: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getStudentById: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    updateStudent: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/update`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    deleteStudent: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/delete`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getNextToGraduateStudentList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-next-to-graduate-student-list`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    getGraduateStudentList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-graduated-student-list`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    getGraduateListByStudentEducationId: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-grade-list-by-studentId`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    updateStudentGrade: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/grade/update`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getTaskListByStudentEducationId: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-task-list-by-studentId`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    approveStudentTask: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/task/approve`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getStudentElastics: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/search`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getInstallmentListByStudentEducationId: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-payment-list-by-student-education-id`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    confirmInstallmentById: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/confirm-installment-by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    bulkInstallmentById: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/bulk-installment-by-id`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getExpiredPaymentList: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-expired-payment-list`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    getEducationsByStudentId: builder.query({
      query: (data) => ({
        url: `${apiUrl}/student/get-educations-by-student-id`,
        method: "POST",
        credentials: "include",
        body: { data },
      }),
    }),
    updateLoginStatusByStudentId: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/update-login-status`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    upgradeTerm: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/upgrade-term`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    upgradeGraduate: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/upgrade-graduate`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    directGraduate: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/student/direct-graduate`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useAddStudentMutation,
  useGetStudentsQuery,
  useGetStudentByIdQuery,
  useGetStudentElasticsQuery,
  useGetNextToGraduateStudentListQuery,
  useGetGraduateStudentListQuery,
  useGetGraduateListByStudentEducationIdQuery,
  useUpdateStudentGradeMutation,
  useGetTaskListByStudentEducationIdQuery,
  useApproveStudentTaskMutation,
  useGetInstallmentListByStudentEducationIdQuery,
  useConfirmInstallmentByIdMutation,
  useBulkInstallmentByIdMutation,
  useGetExpiredPaymentListQuery,
  useUpdateStudentMutation,
  useGetEducationsByStudentIdQuery,
  useUpdateLoginStatusByStudentIdMutation,
  useUpgradeTermMutation,
  useUpgradeGraduateMutation,
  useDirectGraduateMutation,
} = extendedApi;
