import { api } from "./api";
const apiUrl = "https://admin.icba.uk/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addLessonDocument: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/lesson-document/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getLessonDocuments: builder.query({
      query: (data) => ({
        url: `${apiUrl}/lesson-document/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useAddLessonDocumentMutation, useGetLessonDocumentsQuery } =
  extendedApi;
