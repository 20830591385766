import { api } from "./api";
const apiUrl = "https://admin.icba.uk/api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    addExam: builder.mutation({
      query: (data) => ({
        url: `${apiUrl}/exam/add`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
    getExams: builder.query({
      query: (data) => ({
        url: `${apiUrl}/exam/list`,
        method: "POST",
        body: { data },
        credentials: "include",
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useAddExamMutation, useGetExamsQuery } = extendedApi;
