import { saveAs } from 'file-saver';
import { AlignmentType, PageNumber, Document, Footer, Header, Packer, Paragraph, TextRun, Table, TableRow, TableCell, BorderStyle, WidthType, TableLayoutType, HeadingLevel, HeightRule, ShadingType, convertInchesToTwip, RelativeHorizontalPosition, TableAnchorType, RelativeVerticalPosition, OverlapType, ImageRun, TextWrappingType, TextWrappingSide } from "docx";
import moment from 'moment/moment';
import { logobase } from './logobase';


const tableBorderStyle = {
    top: {
        style: BorderStyle.SINGLE,
        size: 3,
        color: "#000000",
    },
    bottom: {
        style: BorderStyle.SINGLE,
        size: 3,
        color: "#000000",
    },
    left: {
        style: BorderStyle.SINGLE,
        size: 3,
        color: "#000000",
    },
    right: {
        style: BorderStyle.SINGLE,
        size: 3,
        color: "#000000",
    },
}
const tableCellLabelWidth = { type: WidthType.PERCENTAGE, size: 20 }
const tableCellValueWidth = { type: WidthType.PERCENTAGE, size: 30 }

export const printOrder = (data, showPrice) => {
    const name = data?.studentEducation_student?.name ?? ""
    const surname = data?.studentEducation_student?.surname ?? ""
    const dateOfBirth = data?.studentEducation_student?.birthDate ? (moment(data?.studentEducation_student?.birthDate).format("DD.MM.YYYY")) : "" ?? ""
    const department = data?.studentEducation_education?.name ?? ""
    const diploma = data?.certificateNo ?? ""

    const prepareSemester = (data, key) => {
        let notesRows = []
        let total = 0;
        notesRows.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({ children: [new TextRun({ text: `${key}. Semester`, bold: true, size: 32, font: "Calibri", alignment: AlignmentType.CENTER, color: "#ffffff" })], alignment: "center" })],
                    borders: tableBorderStyle,
                    width: tableCellLabelWidth,
                    columnSpan: 5,
                    shading: {
                        fill: "003965",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto",
                    },

                })
            ]
        }))

        notesRows.push(
            new TableRow({
                children: [
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: "CODE", color: "#b51919" })], alignment: AlignmentType.CENTER })],
                        borders: tableBorderStyle,
                        width: { type: WidthType.PERCENTAGE, size: 10 }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: "CREDIT", color: "#b51919" })], alignment: AlignmentType.CENTER })],
                        borders: tableBorderStyle,
                        width: { type: WidthType.PERCENTAGE, size: 10 }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: "COURSE NAME", color: "#b51919" })], alignment: AlignmentType.CENTER })],
                        borders: tableBorderStyle,
                        width: { type: WidthType.PERCENTAGE, size: 40 }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: "COURSE TYPE", color: "#b51919" })], alignment: AlignmentType.CENTER })],
                        borders: tableBorderStyle,
                        width: { type: WidthType.PERCENTAGE, size: 20 }
                    }),
                    new TableCell({
                        children: [new Paragraph({ children: [new TextRun({ text: "NOTE", color: "#b51919" })], alignment: AlignmentType.CENTER })],
                        borders: tableBorderStyle,
                        width: { type: WidthType.PERCENTAGE, size: 10 }
                    }),
                ]
            })
        )

        for (let i = 0; i < data.length; i++) {
            const note = data[i];
            total += note.credit ? note.credit : 0
            notesRows.push(
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: note.code })], alignment: AlignmentType.CENTER })],
                            borders: tableBorderStyle,
                            width: { type: WidthType.PERCENTAGE, size: 10 }
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: note.credit.toString() })], alignment: AlignmentType.CENTER })],
                            borders: tableBorderStyle,
                            width: { type: WidthType.PERCENTAGE, size: 10 }
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: note.lessonName })], alignment: AlignmentType.LEFT })],
                            borders: tableBorderStyle,
                            width: { type: WidthType.PERCENTAGE, size: 40 }
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: note.lessonType })], alignment: AlignmentType.LEFT })],
                            borders: tableBorderStyle,
                            width: { type: WidthType.PERCENTAGE, size: 20 }
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: note.note })], alignment: AlignmentType.CENTER })],
                            borders: tableBorderStyle,
                            width: { type: WidthType.PERCENTAGE, size: 10 }
                        }),
                    ]
                })
            )
        }

        notesRows.push(new TableRow({
            children: [
                new TableCell({
                    children: [new Paragraph({ children: [new TextRun({ text: `Total`, bold: true, size: 26, font: "Calibri", color: "#ffffff" })] })],
                    borders: tableBorderStyle,
                    width: tableCellLabelWidth,
                    shading: {
                        fill: "003965",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto",
                    },
                }),
                new TableCell({
                    children: [new Paragraph({ children: [new TextRun({ text: `${total}`, bold: true, size: 26, font: "Calibri", color: "#ffffff" })] })],
                    borders: tableBorderStyle,
                    width: tableCellLabelWidth,
                    columnSpan: 4,
                    shading: {
                        fill: "003965",
                        type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                        color: "auto",
                    },
                })
            ]
        }))

        return { notesRows, total }
    }

    const getSemesterData = (semesters) => {
        let arr = []
        let grandTotal = 0
        Object.keys(semesters).forEach(function (key) {
            const data = prepareSemester(semesters[key], key)
            arr.push(new Table({
                width: { type: WidthType.PERCENTAGE, size: 100 },
                layout: TableLayoutType.FIXED,
                rows: data.notesRows,
            }))
            arr.push(new Paragraph("-"))
            grandTotal += data.total
        });
        //Footer table
        arr.push(new Table({
            width: { type: WidthType.PERCENTAGE, size: 100 },
            layout: TableLayoutType.FIXED,
            style: { margin: 10 },
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: `TOTAL GRADUATION CREDITS`, bold: true, size: 26, font: "Calibri", color: "#ffffff" })] })],
                            borders: tableBorderStyle,
                            width: tableCellLabelWidth,
                            shading: {
                                fill: "003965",
                                type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                                color: "auto",
                            },
                        }),
                        new TableCell({
                            children: [new Paragraph({ children: [new TextRun({ text: `${grandTotal.toString()}`, bold: true, size: 26, font: "Calibri", color: "#ffffff" })] })],
                            borders: tableBorderStyle,
                            width: tableCellLabelWidth,
                            columnSpan: 4,
                            shading: {
                                fill: "003965",
                                type: ShadingType.REVERSE_DIAGONAL_STRIPE,
                                color: "auto",
                            },
                        })
                    ]
                })
            ]
        }))
        return arr
    }

    var dataUrl = "data:image/png;base64," + logobase;

    const getData = async () => {
        var fi = await fetch(dataUrl)

        var d = await fi.arrayBuffer()
        return d
        return new Uint8Array(d)
    }

    // console.log({ logobase })
    // var binaryString = atob("data:image/png;base64," + logobase);
    // var bytes = new Uint8Array(binaryString.length);
    // for (var i = 0; i < binaryString.length; i++) {
    //     bytes[i] = binaryString.charCodeAt(i);
    // }
    const doc = new Document({
        sections: [
            {
                headers: {
                    default: new Header({
                        children: [
                            // new Paragraph({
                            //     children: [new ImageRun({
                            //         data: getData(), transformation: { width: 150, height: 150 }, floating: {
                            //             horizontalPosition: {
                            //                 offset: 201440,
                            //             },
                            //             verticalPosition: {
                            //                 offset: 201440,
                            //             },
                            //             wrap: {
                            //                 type: TextWrappingType.SQUARE,
                            //                 side: TextWrappingSide.BOTH_SIDES,
                            //             },
                            //             margins: {
                            //                 top: 201440,
                            //                 bottom: 201440,
                            //             },
                            //         }
                            //     })]
                            // }),
                            new Paragraph({ text: moment().format("DD.MM.YYYY"), alignment: "right", heading: HeadingLevel.HEADING_4 }),
                            new Paragraph({ text: "INTERNATIONAL BRITANNIA UNIVERSITY", alignment: "center", heading: HeadingLevel.HEADING_1 }),
                            new Paragraph({ text: "STUDENT AFFAIRS", alignment: "center", heading: HeadingLevel.HEADING_1 }),
                            new Paragraph({ text: "STUDENT TRANSCRIPT DOCUMENT", alignment: "center", heading: HeadingLevel.HEADING_1 }),
                        ],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph({
                                alignment: AlignmentType.RIGHT,
                                children: [
                                    new TextRun({
                                        children: ["Sayfa ", PageNumber.CURRENT],
                                    }),
                                    new TextRun({
                                        children: [" / ", PageNumber.TOTAL_PAGES],
                                    }),
                                ],
                            }),
                        ],
                    })
                },
                children: [
                    new Paragraph({
                        children: [new ImageRun({
                            data: getData(), transformation: { width: 150, height: 150 }, floating: {
                                horizontalPosition: {
                                    offset: 201440,
                                },
                                verticalPosition: {
                                    offset: 201440,
                                },
                                wrap: {
                                    type: TextWrappingType.SQUARE,
                                    side: TextWrappingSide.BOTH_SIDES,
                                },
                                margins: {
                                    top: 201440,
                                    bottom: 201440,
                                },
                            }
                        })]
                    }),
                    new Paragraph({ children: [new TextRun({ text: "", break: 3 })] }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "NAME", bold: true, size: 22, font: "Calibri" }),
                            new TextRun("                  :  "),
                            new TextRun({ text: `${name}`, size: 22, font: "Calibri" })]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "SURNAME", bold: true, size: 22, font: "Calibri" }),
                            new TextRun("           :  "),
                            new TextRun({ text: `${surname}`, size: 22, font: "Calibri" })]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "DATE OF BIRTH", bold: true, size: 22, font: "Calibri" }),
                            new TextRun("  :  "),
                            new TextRun({ text: `${dateOfBirth}`, size: 22, font: "Calibri" })]
                    }),
                    // new Paragraph({
                    //     children: [
                    //         new TextRun({ text: "FACULTY", bold: true, size: 22, font: "Calibri" }),
                    //         new TextRun("  "),
                    //         new TextRun({ text: `${name}`, size: 22, bold: true, font: "Calibri" })]
                    // }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "DEPARTMENT", bold: true, size: 22, font: "Calibri" }),
                            new TextRun("    :  "),
                            new TextRun({ text: `${department}`, size: 22, font: "Calibri" })]
                    }),
                    new Paragraph({
                        children: [
                            new TextRun({ text: "DIPLOMA", bold: true, size: 22, font: "Calibri" }),
                            new TextRun("            :  "),
                            new TextRun({ text: `${diploma}`, size: 22, font: "Calibri" })]
                    }),
                    new Paragraph(" "),
                    new Paragraph(" "),
                    new Paragraph(" "),
                    ...getSemesterData(data?.notes),

                    new Paragraph({
                        children: [
                            new TextRun({ text: "This transcript is given as per request of the student as statistical data prepared by relevant department on student grades.", break: 1 }),
                            new TextRun({ text: "International Britannia University Transcript General Grade Remarks", break: 1 }),
                            new TextRun({ text: "Included in Average  Not Included in Average", break: 1 }),
                            new TextRun({ text: "AA 4,0 AB 3,7 BA 3,3", break: 1 }),
                            new TextRun({ text: "BB 3,0 BC 2,7 CB 2,3", break: 1 }),
                            new TextRun({ text: "CC 2,0 CD 1,7 DC 1,3", break: 1 }),
                            new TextRun({ text: "DD 1,0 FF 0,0", break: 1 }),
                            new TextRun({ text: "YT Satisfactory: Indicates the student passes a course which is not included in grade average.", break: 1 }),
                            new TextRun({ text: "EK Incomplete: Indicates the student has not completed the requirements of the course yet.", break: 1 }),
                            new TextRun({ text: "MU Exempt: Indicates the student is exempted from a course by a relevant Science Board decision or from courses succeeded in another higher education institution.", break: 1 }),
                            new TextRun({ text: "KL Removed: Indicates a course for which the student is not responsible because it is removed from schedule.", break: 1 }),
                            new TextRun({ text: "SD Not Responsible: Indicates courses the student will not be responsible following course adjustment.", break: 1 }),
                            new TextRun({ text: "DV Continues: Given to the student who still continues to a course that lasts more than one semester, at the end of a semester which it is not completed.", break: 1 }),
                            new TextRun({ text: "GENERAL REMARKS", break: 1 }),
                            new TextRun({ text: "Below remarks apply to all students who get trained though distance learning from International Britannia University.", break: 1 }),
                            new TextRun({ text: "A student who receives CC or above grade (AA, AB, BA, BB, BC, CB and CC) is considered to pass the course, regardless of General Grade Average or “ACADEMIC STATUS”.", break: 1 }),
                            new TextRun({ text: "A student who receives FF, DZ or YZ must take the course in the first semester it is offered, regardless of General Grade Average or “ACADEMIC STATUS”.", break: 1 }),
                            new TextRun({ text: "The status of a student who receives On Probation grade (CD, DC and DD) is determined according to “ACADEMIC STATUS”.  “ACADEMIC STATUS” is determined as per General Grade Average as follows:", break: 1 }),
                            new TextRun({ text: "The status of a student whose General Grade Average is 2.00 and above: SUCCESSFUL", break: 1 }),
                            new TextRun({ text: "The “first” status of a student whose General Grade Average is below 2.00 : WARNING.", break: 1 }),
                            new TextRun({ text: "The status of a student whose General Grade Average is still 2.00 after receiving WARNING: REPEAT", break: 1 }),
                            new TextRun({ text: "Academic Status of a student who secures General Grade Average of 2.00 is considered “SUCCESSFUL”. A student with “SUCCESSFUL” status is considered to pass courses with grades of CC and above and courses with On Probation grades (CC, DC and DD) but must repeat courses with grades FF, DZ and YZ in relevant semester.", break: 1 }),
                            new TextRun({ text: "A student who cannot secure General Grade Average of 2.00 is given warning of academic inadequacy and Academic Status is seen as “WARNING”. A student who was given academic inadequacy must have a General Grade Average of at least 2.00 at the end of next semester. A student whose Academic Status is “WARNING” can only take courses with grades FF, DZ and YZ in the next semester. If a student whose Academic Status is “WARNING” increases General Grade Average to at least 2.00 in the next semester then Academic Status is changed to “SUCCESSFUL”.", break: 1 }),
                            new TextRun({ text: "If a student whose Academic Status is “WARNING” cannot increase General Grade Average to at least 2.00 in the next semester then Academic Status is changed to “REPEAT”. A student whose Academic Status is “REPEAT” must repeat courses with On Probation grades (CD, DC, DD) along with courses with grades FF, DZ and YZ in the relevant semester re-registered. ", break: 1 }),
                            new TextRun({ text: "A student whose Academic Status is “REPEAT” and who repeats courses, must take courses with On Probation grades (CD, DC, DD) along with courses with grades FF, DZ and YZ until General Grade Average is at least 2.00. If a student whose Academic Status is “REPEAT” and repeats courses has General Grade Average at least 2.00 at the end of the semester repeated, then repeat of courses ends and Academic Status is changed to “SUCCESSFUL”.", break: 1 }),
                            new TextRun({ text: "Courses in semester-credit system corresponding to courses taken and passed in Annual System are shown for information purposes. When Academic Status, which is formed according to General Grade Average on Grade Status Document, is Warning, course status is changed to On Probation; course status is changed to Failed when it is Repeat and course status is changed to Pass in Semester-System when Successful.", break: 1 }),
                            new TextRun({ text: "In addition, semesters with odd numbers are taught in Fall Semester and semesters with even numbers are taught in Spring Semester. A Fall Semester course cannot be chosen in Spring Semester; A Spring Semester course cannot be chosen in Fall Semester. A student who has a course from First semester cannot choose course from fifth and seventh semesters; a student who has a course from Second semester cannot choose course from sixth and eighth semesters; a student who has a course from Third semester cannot choose course from seventh semester and a student who has a course from Fourth semester cannot choose course from eighth semester.", break: 1 }),
                            new TextRun({ text: "ATTENTION:  In order to graduate from a program, a student must have a General Grade Average of 2.00; must not have “Failed” courses (No FF, YZ or DZ grades) and there must not be any courses not taken (Total credits must be at least 120 for associate degrees and at least 240 for undergraduate degrees).", break: 1 }),
                        ],
                        alignment: AlignmentType.CENTER
                    }),
                    // new Paragraph({
                    //     children: [
                    //         new TextRun({ text: "University Center and Representative Offices", bold: true, size: 26, break: 1 })
                    //     ]
                    // }),
                    // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     style: { margin: 10 },
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `GREAT BRITAIN REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `England - LONDON`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Registered Office:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `20-22 Wenlock Road N17GU`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Publicity Office:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `96 Lover Clapton Road E5 Oqr`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `075 0390 96 00`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                    // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     style: { margin: 10 },
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `GREECE REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Greece`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `215 - 50 13 973`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `695 30 11 277`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                    // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     style: { margin: 10 },
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `TURKEY REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Adres: Istanbul – Atasehir`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `İçerenköy Mahallesi Topcu Ibrahim Sokak Tahsin Bey Apartmani No:9 Kat:5 Daire:10`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `0850 333 52 21 / 0216 362 00 66`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                    // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     style: { margin: 10 },
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `RUSSIA REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `РФ,РТ, г. Казань, ул. Чернышевского д. 33 оф. 5`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `+7 843 517 27 75`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `+7 925 055 45 09`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                    // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     style: { margin: 10 },
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `THE UNITED STATES REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `New York: One World Trade Center, Lower Manhattan`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `One World Trade Center`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Suite 8500`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `New York, NY 10007`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `+1 212-739-0487`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Fax:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `1 212-739-0425`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                    // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `UKRAINA REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Prospekt Pobedi 104 b. Kiev / Ukraine`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `00 38 04 44 24 21 9`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                    // // new Paragraph({ children: [new TextRun({ text: "", break: 1 })] }),
                    // new Table({
                    //     width: { type: WidthType.PERCENTAGE, size: 50 },
                    //     layout: TableLayoutType.FIXED,
                    //     rows: [
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: ` AZERBAYCAN REPRESENTATION`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Akademik Həsən Əliyev Prospekti`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Aşıq Molla Cümə küçəsi 138,`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `İqtisad univeristetinin Gənclik korpusunun yanı`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Beynəlxalq Təlim və Layihə Mərkəzi, 3-cü mərt`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                     columnSpan: 2
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `994507211581`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `994516506151`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Telephone:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `994125641364`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Web:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `www.icba.uk`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //         new TableRow({
                    //             children: [
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `Email:`, bold: true, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //                 new TableCell({
                    //                     children: [new Paragraph({ children: [new TextRun({ text: `info@icba.uk`, size: 18, font: "Calibri" })] })],
                    //                     borders: tableBorderStyle,
                    //                     width: tableCellLabelWidth,
                    //                 }),
                    //             ]
                    //         }),
                    //     ]
                    // }),
                ],
            },
        ],
    });

    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${diploma}_${name}_${surname}_${new Date().getFullYear()}_${new Date().getMonth() + 1}_${new Date().getDate()}.docx`)
    });
}