import {
  Task,
  ArrowLeftLine,
  ArrowRightLine,
  Gear,
  Setting,
} from "@rsuite/icons";
import React, { useState } from "react";
import color from "../../styles/colors";
import CheckIcon from "@rsuite/icons/Check";
import { Nav, Navbar, Sidebar, Sidenav, Toggle } from "rsuite";
import "./style.css";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useUser from "../../hooks/useUser";

const NavToggle = ({ expand, onExpandStateChange }) => {
  return (
    <Navbar
      appearance="subtle"
      style={{
        position: "fixed",
        bottom: 0,
        zIndex: 9999,
        width: "inherit",
        backgroundColor: "var(--rs-bg-well)",
      }}
    >
      {/* <Nav>
                <Nav.Menu
                    placement="topStart"
                    trigger="click"
                    renderTitle={children => {
                        return <div style={{
                            width: 56,
                            height: 56,
                            padding: 18,
                            lineHeight: '56px',
                            textAlign: 'center'
                        }} />;
                    }}
                >
                    {color.map((item, index) => (
                        <Nav.Item key={index}><Avatar circle size="sm" style={{ background: item.color }}><CheckIcon style={{ fontSize: 14 }} /></Avatar></Nav.Item>
                    ))}
                </Nav.Menu>
            </Nav> */}
      <Nav pullRight>
        <Nav.Item
          onClick={onExpandStateChange}
          style={{ width: 56, textAlign: "center" }}
        >
          {expand ? <ArrowLeftLine /> : <ArrowRightLine />}
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

const MainSidebar = () => {
  const [expand, setExpand] = useState(true);
  const { pathname } = useLocation();
  const [active, setActiveItem] = useState(pathname);
  const history = useNavigate();
  const { t } = useTranslation();
  const [currentUser] = useUser();
  console.log(currentUser)
  const onSelect = (key) => {
    setActiveItem(key);
    if (key) history(key);
  };

  return (
    <Sidebar
      style={{ display: "flex", flexDirection: "column", height: "auto" }}
      width={expand ? 260 : 56}
      collapsible
    >
      <Sidenav.Header>
        <div
          style={{
            padding: 18,
            fontSize: 16,
            height: 56,
            background: "#34c3ff",
            color: " #fff",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          {!expand && <Gear style={{ fontSize: 20 }} />}
          {expand && <span style={{ marginLeft: 12 }}> International Britannia University</span>}
        </div>
      </Sidenav.Header>
      <Sidenav
        expanded={expand}
        // defaultOpenKeys={['3']}
        appearance="subtle"
      >
        {/* style={{ overflow: 'hidden auto', }} */}
        <Sidenav.Body>
          <Nav vertical={true} activeKey={active} onSelect={onSelect}>
            <Nav.Item eventKey="/" icon={<Setting />}>
              {t("pages.menu.home")}
            </Nav.Item>
            {/* <Nav.Menu
              eventKey="3"
              title={t("pages.menu.company")}
              icon={<Gear />}
              placement="rightStart"
            >
              <Nav.Item eventKey="/company/companies">
                {t("pages.menu.companies")}
              </Nav.Item>
              <Nav.Item eventKey="/company/company-addresses">
                {t("pages.menu.companyAddress")}
              </Nav.Item>
            </Nav.Menu> */}
            {/* <Nav.Menu
                            eventKey="4"
                            title={t("pages.menu.order")}
                            icon={<Task />}
                            placement="rightStart"
                        >
                            <Nav.Item eventKey="/order/list">{t("pages.menu.orders")}</Nav.Item>
                            <Nav.Menu
                                eventKey="4-1"
                                title={t("pages.menu.orderDefinitions")}
                                placement="rightStart"
                            >
                                <Nav.Item eventKey="/order/order-prefix/list">{t("pages.menu.orderPrefixes")}</Nav.Item>
                                <Nav.Item eventKey="/order/order-type/list">{t("pages.menu.orderTypes")}</Nav.Item>
                                <Nav.Item eventKey="/order/order-status/list">{t("pages.menu.orderStatus")}</Nav.Item>
                                <Nav.Item eventKey="/product/product-type/list">{t("pages.menu.productType")}</Nav.Item>
                                <Nav.Item eventKey="/settings/knit-type/list">{t("pages.menu.knitType")}</Nav.Item>
                            </Nav.Menu>
                        </Nav.Menu> */}
            {currentUser && currentUser.roleId === 1 && <Nav.Menu
              eventKey="4"
              title={t("pages.menu.administrativeAffairs")}
              icon={<Gear />}
              placement="rightStart"
            >
              <Nav.Item eventKey="/educations/list">
                {t("pages.menu.educations")}
              </Nav.Item>
              {/* <Nav.Item eventKey="/education-expertise/list">
                {t("pages.menu.educationExpertises")}
              </Nav.Item> */}
              <Nav.Item eventKey="/lessons/list">
                {t("pages.menu.lessons")}
              </Nav.Item>
              <Nav.Item eventKey="/lesson-task/list">
                {t("pages.menu.lessonTask")}
              </Nav.Item>
              <Nav.Item eventKey="/lesson-video/list">
                {t("pages.menu.lessonVideo")}
              </Nav.Item>
              <Nav.Item eventKey="/lesson-document/list">
                {t("pages.menu.lessonDocument")}
              </Nav.Item>
              <Nav.Item eventKey="/exam/list">
                {t("pages.menu.exam")}
              </Nav.Item>
              <Nav.Item eventKey="/teacher/list">
                {t("pages.menu.teacher")}
              </Nav.Item>
              <Nav.Item eventKey="/agency/list">
                {t("pages.menu.agency")}
              </Nav.Item>
            </Nav.Menu>}
            <Nav.Menu
              eventKey="5"
              title={t("pages.menu.students")}
              icon={<Gear />}
              placement="rightStart"
            >
              <Nav.Item eventKey="/student/list">
                {t("pages.menu.student")}
              </Nav.Item>
              <Nav.Item eventKey="/student/next-to-graduate-student-list">
                {t("pages.menu.nextToGraduateStudent")}
              </Nav.Item>
              <Nav.Item eventKey="/student/graduated-student-list">
                {t("pages.menu.graduatedStudent")}
              </Nav.Item>
              <Nav.Item eventKey="/student/task">
                {t("pages.menu.studentTask")}
              </Nav.Item>
              <Nav.Item eventKey="/student/grade">
                {t("pages.menu.studentGrade")}
              </Nav.Item>
              {currentUser && currentUser.roleId === 1 && <Nav.Item eventKey="/student/installment">
                {t("pages.menu.studentInstallment")}
              </Nav.Item>}
              {currentUser && currentUser.roleId === 1 && <Nav.Item eventKey="/student/expired-payments">
                {t("pages.menu.studentExpiredPayment")}
              </Nav.Item>}
              {currentUser && currentUser.roleId === 1 && <Nav.Item eventKey="/student/direct-graduate">
                {t("pages.menu.studentDirectGraduate")}
              </Nav.Item>}
            </Nav.Menu>
            {currentUser && currentUser.roleId === 1 && <Nav.Menu
              eventKey="6"
              title={t("pages.menu.settings")}
              icon={<Gear />}
              placement="rightStart"
            >
              <Nav.Item eventKey="/settings/language/list">
                {t("pages.menu.languages")}
              </Nav.Item>
              <Nav.Item eventKey="/settings/term/list">
                {t("pages.menu.terms")}
              </Nav.Item>
              <Nav.Item eventKey="/settings/education-level/list">
                {t("pages.menu.educationLevel")}
              </Nav.Item>
              <Nav.Item eventKey="/settings/discount/list">
                {t("pages.menu.discount")}
              </Nav.Item>
            </Nav.Menu>}
            <Nav.Menu
              eventKey="7"
              title={t("pages.menu.account")}
              icon={<Gear />}
              placement="rightStart"
            >
              <Nav.Item eventKey="/change-password">
                {t("pages.menu.change_password")}
              </Nav.Item>

            </Nav.Menu>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
      <NavToggle
        expand={expand}
        onExpandStateChange={() => setExpand(!expand)}
      />
    </Sidebar>
  );
};

export default MainSidebar;
