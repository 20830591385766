import { Edit, FileDownload } from "@rsuite/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "rsuite";
import DataTable from "../../../components/dataTable";
import Page from "../../../components/layout/Page";
import { useGetLessonDocumentsQuery } from "../../../redux/lessonDocumentApi";
import { defaultToolbar, initParams } from "../../../utils";

const LessonDocuments = () => {
  const { t } = useTranslation();
  const [params, setParams] = useState(initParams);
  const { data, isLoading, refetch } = useGetLessonDocumentsQuery(params, {
    refetchOnMountOrArgChange: true,
  });

  const download = (data) => {};

  const columns = [
    {
      title: t("pages.lessonDocument.educationId"),
      name: "educationId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.lessonDocument_education
            ? rowData.lessonDocument_education?.code +
              " - " +
              rowData.lessonDocument_education?.name
            : ""}
        </span>
      ),
    },
    {
      title: t("pages.lessonDocument.lessonId"),
      name: "lessonId",
      sortable: true,
      align: "left",
      type: "ref",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>
          {rowData.lessonDocument_lesson
            ? rowData.lessonDocument_lesson?.code +
              " - " +
              rowData.lessonDocument_lesson?.name
            : ""}
        </span>
      ),
    },
    {
      title: t("pages.lessonDocument.name"),
      name: "name",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 1,
    },
    {
      title: t("pages.lessonDocument.description"),
      name: "description",
      sortable: true,
      align: "left",
      type: "text",
      flexGrow: 2,
    },
    {
      title: t("pages.lessonDocument.isAccessAll"),
      name: "isAccessAll",
      sortable: false,
      align: "left",
      type: "boolean",
      flexGrow: 1,
      renderCell: (rowData) => (
        <span>{rowData.isAccessAll ? t("general.yes") : t("general.no")}</span>
      ),
    },
    {
      title: t("pages.lessonDocument.downloadCount"),
      name: "downloadCount",
      sortable: true,
      align: "left",
      type: "number",
      flexGrow: 1,
    },
    {
      title: t("general.table.actions"),
      name: "actions",
      width: 100,
      renderCell: (rowData) => (
        <>
          {/* <IconButton
                        onClick={() => download(rowData)}
                        size="xs"
                        icon={<FileDownload color="blue" />}
                    /> */}
          {/* <a
            download={rowData?.path?.substring(
              rowData?.path.lastIndexOf("/") + 1
            )}
            target="_blank"
            href={`https://admin.icba.uk/api/documents/${rowData?.path}`}
          >
            İndir
          </a> */}
          <a
            // download={rowData?.path?.substring(
            //   rowData?.path.lastIndexOf("/") + 1
            // )}
            download={`https://admin.icba.uk/api/public/documents/${rowData?.path}`}
            target="_blank"
            href={`https://admin.icba.uk/api/public/documents/${rowData?.path}`}
          >
            İndir
          </a>
        </>
      ),
    },
  ];

  return (
    <Page title={t("pages.lessonDocument.title")}>
      <DataTable
        create="/lesson-document/add"
        size="compact"
        columns={columns}
        rowKey="id"
        loading={isLoading}
        data={data?.data}
        params={params}
        rowCount={data?.total}
        setPagination={setParams}
        refetch={refetch}
        toolbar={{ ...defaultToolbar, filter: false }}
      />
    </Page>
  );
};

export default LessonDocuments;
